import React from "react";
import "./styles.css";

const App = () => {
  return (
    <div className="app-container">
      <header>{/* Header content (if needed) */}</header>
      <main>
        <div className="content-wrapper">
          <img
            className="headshot"
            src="your_headshot.jpg"
            alt="Andrew Gordon's Headshot"
          />
          <h1>Welcome!</h1>
          <p>Thanks for visiting my site!</p>
          <p>
            I'm a Christian, husband, father, technical business consultant, and
            musician.
          </p>

          <a
            href="https://www.linkedin.com/in/theandrewgordon/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="linkedin-button">Connect on LinkedIn</button>
          </a>
        </div>
      </main>
      <footer>
        &copy; 2024 Andrew Gordon |{" "}
        <a href="mailto:info@theandrewgordon.com">Contact Me</a>
      </footer>
    </div>
  );
};

export default App; // This is the crucial export!
