import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Correct import path
import reportWebVitals from "./reportWebVitals"; // Keep this for performance tracking

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App /> {/* Use the imported App component */}
  </React.StrictMode>
);

reportWebVitals();
